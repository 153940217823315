import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "../website/Website.module.scss";
import { Link } from "react-router-dom";
export class Website extends Component {
  static propTypes = {};
  render() {
    const websiteInfo = [
      {
        url: "https://www.cyberhelpindia.com/chi-admin/products/15940646326028382.png",
        name: "Nano Website Design",
        pra: "3 - 4 NOS. OF WEB PAGES",
        infoList: [
          {
            list: " Excellent Responsive Website",
          },
          {
            list: " Mobile Responsive Layout",
          },
          {
            list: " Facebook Page Integration",
          },
          {
            list: " Youtube Page Integration",
          },
          {
            list: " Instagram Page Integration",
          },
          {
            list: " Whatsapp Chat Integration",
          },
          {
            list: " 1 Email IDs for Outlook.",
          },
          {
            list: " Free Web Hosting for 1 Year",
          },
          {
            list: " Free Domain for 1 Year",
          },
        ],
        del: "Delivery in :",
        day: "7day",
        butonOne: "get Quote",
        buttonTwo: "details",
      },
      {
        url: "https://www.cyberhelpindia.com/chi-admin/products/1383starter.png",
        name: "Starter Website Design",
        pra: "6 NOS. OF WEB PAGES",
        infoList: [
          {
            list: " Excellent Responsive Website",
          },
          {
            list: " Mobile Responsive Layout",
          },
          {
            list: " Your Brand Logo Design.",
          },
          {
            list: " Your Website Banner & Graphics Design",
          },
          {
            list: " Meta tags for Each Page",
          },
          {
            list: " Meta Description for Each Page",
          },
          {
            list: " FREE Social Media Integration: Facebook, Instagram, Youtube",
          },
          {
            list: " Whatsapp Chat Integration.",
          },
          {
            list: " 2 Email IDs for Outlook",
          },
        ],
        del: "Delivery in :",
        day: "15day",
        butonOne: "get Quote",
        buttonTwo: "details",
      },
      {
        url: "https://www.cyberhelpindia.com/chi-admin/products/7457standard.png",
        name: "Standard Website Design",
        pra: "15 - 20 NOS. OF WEB PAGES",
        infoList: [
          {
            list: " Excellent Responsive Website",
          },
          {
            list: " Mobile Responsive Layout",
          },
          {
            list: " Your Logo, Graphics & Banners",
          },
          {
            list: " Free Web Hosting for 1 Year",
          },
          {
            list: " Free Domain for 1 Year",
          },
          {
            list: " SSL Certificate for 1 Year",
          },
          {
            list: " Google Map Integration",
          },
          {
            list: " FREE Social Media Integration: Facebook, Twitter, Instagram, Youtube",
          },
          {
            list: " Whatapp Chat Integration",
          },
        ],
        del: "Delivery in :",
        day: "7day",
        butonOne: "get Quote",
        buttonTwo: "details",
      },
      {
        url: "https://www.cyberhelpindia.com/chi-admin/products/15975219255671727.png",
        name: "Basic E-Commerce Website",
        pra: "FOR FOOD PRODUCT / GROCERY STORE / ESSENTIAL SERVICE",
        infoList: [
          {
            list: " LOGIN & REGISTRATION FEATURES.",
          },
          {
            list: " CATEGORY OF PRODUCTS:",
          },
          {
            list: " LIST OF PRODUCTS",
          },
          {
            list: " PRODUCT INFORMATION,",
          },
          {
            list: " SIMILAR PRODUCTS",
          },
          {
            list: " PRODUCT SEARCH,",
          },
          {
            list: " ADD TO CART / WISH LIST",
          },
          {
            list: " MY ACCOUNT",
          },
          {
            list: " MY ORDERS / PRODUCT INVOICE",
          },
        ],
        del: "Delivery in :",
        day: "30day",
        butonOne: "get Quote",
        buttonTwo: "details",
      },
    ];

    return (
      <div>
        <section className={styles.website}>
          {websiteInfo.map((web) => {
            return (
              <div>
                <div className={styles.wrap}>
                  <div className={styles.imgs}>
                    <img src={web.url} alt="" />
                  </div>
                  <div className={styles.info}>
                    <h2>
                      <Link
                        href="online-product/nano-website-design"
                        class="th-color"
                      >
                        {web.name}
                      </Link>
                    </h2>
                    <p>{web.pra}</p>
                    <ul>
                      {web.infoList.map((weblist) => {
                        return <li>{weblist.list}</li>;
                      })}
                    </ul>
                  </div>
                  <div className={styles.btn_grp}>
                    <h3>
                      {web.del} <span>{web.day}</span>{" "}
                    </h3>
                    <button>{web.butonOne}</button>
                    <button>{web.buttonTwo}</button>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
        <section className={styles.website_mobile}>
          <div className="container">
            <div className={styles.back_button}>
              <Link to="/service">
                <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
              </Link>
            </div>
            {websiteInfo.map((web) => {
              return (
                <div>
                  <div className={styles.wrap}>
                    <div className={styles.imgs}>
                      <img src={web.url} alt="" />
                    </div>
                    <div className={styles.info}>
                      <h2>
                        <Link
                          href="online-product/nano-website-design"
                          class="th-color"
                        >
                          {web.name}
                        </Link>
                      </h2>
                      <p>{web.pra}</p>
                      <div className={styles.btn_grp}>
                        <h3>
                          {web.del} <span>{web.day}</span>{" "}
                        </h3>
                        <button>{web.butonOne}</button>
                        <button>{web.buttonTwo}</button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    );
  }
}

export default Website;
