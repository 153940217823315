import React from "react";
import { Link, Outlet } from "react-router-dom";
import styles from "../service/Service.module.scss";
import Website from "./website/Website";
function Service() {
  document.title = "Datavedam - Services";
  return (
    <div>
      <section className={styles.service}>
        <div className={styles.heading}>
          <h1>Our Services</h1>
          <p>
            For more information call 7407775349 | Or Get A Quote
            <Link>Click Here</Link>
          </p>
        </div>
        <div className="container">
          <div className={styles.flx_box}>
            <div className={styles.left_wrap}>
              <ul>
                {/* <li className={styles.ul_hed}>Select Category</li> */}
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/1.svg"
                    alt=""
                  />
                  <Link to="website">Website Development</Link>
                </li>
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/3.svg"
                    alt=""
                  />
                  <Link to="mobile">Mobile App Development</Link>
                </li>{" "}
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/10.svg"
                    alt=""
                  />
                  <Link to="soft">Software Development</Link>
                </li>{" "}
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/2.svg"
                    alt=""
                  />
                  <Link to="google">Google Ads</Link>
                </li>{" "}
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/5.svg"
                    alt=""
                  />
                  <Link to="fb">Facebook Ads</Link>
                </li>{" "}
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/6.svg"
                    alt=""
                  />
                  <Link to="insta">Instagram Ads</Link>
                </li>{" "}
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/11.svg"
                    alt=""
                  />
                  <Link to="ghraphic">Ghraphics Design</Link>
                </li>
              </ul>
            </div>
            <div className={styles.left_wrap_mob}>
              <ul>
                {/* <li className={styles.ul_hed}>Select Category</li> */}
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/1.svg"
                    alt=""
                  />
                  <Link to="/websiteMob">Website Development</Link>
                </li>
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/3.svg"
                    alt=""
                  />
                  <Link to="/mobileMob">Mobile App Development</Link>
                </li>{" "}
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/10.svg"
                    alt=""
                  />
                  <Link to="/softMOb">Software Development</Link>
                </li>{" "}
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/2.svg"
                    alt=""
                  />
                  <Link to="/googleMob">Google Ads</Link>
                </li>{" "}
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/5.svg"
                    alt=""
                  />
                  <Link to="/fbMob">Facebook Ads</Link>
                </li>{" "}
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/6.svg"
                    alt=""
                  />
                  <Link to="/instaMob">Instagram Ads</Link>
                </li>{" "}
                <li>
                  <img
                    src="https://www.cyberhelpindia.com/images/package/11.svg"
                    alt=""
                  />
                  <Link to="/ghraphicMob">Ghraphics Design</Link>
                </li>
              </ul>
            </div>
            <div className={styles.rit_wap}>
              <div className={styles.wrap}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Service;
