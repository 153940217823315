import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "../graphics/Graphic.module.scss";

export class Graphic extends Component {
  static propTypes = {};

  render() {
    const websiteInfo = [
      {
        url: "https://www.cyberhelpindia.com/chi-admin/products/5872dedicated.png",
        name: "Starter Social Post Design",
        pra: "FOR FACEBOOK, INSTAGRAM, WHATSAPP",
        infoList: [
          {
            list: "  PROFILE IMAGE",
          },
          {
            list: " SOCIAL PAGE COVER IMAGE",
          },
          {
            list: " SOCIAL PAGE POSTING",
          },
          {
            list: " SPONSORED AD POSTING DESIGN",
          },
          {
            list: " MAJOR EVENT POSTING. (Total 13 Nos. Post)",
          },
          {
            list: " Whatsapp Chat Integration",
          },
          {
            list: " 1 Email IDs for Outlook.",
          },
          {
            list: " Free Web Hosting for 1 Year",
          },
          {
            list: " Free Domain for 1 Year",
          },
        ],
        del: "Delivery in :",
        day: "30 day",
        butonOne: "get Quote",
        buttonTwo: "details",
      },
      {
        url: "https://www.cyberhelpindia.com/chi-admin/products/5254advanced%20copy.png",
        name: "Basic Branding Pack",
        pra: "7 NO. OF DESIGNS",
        infoList: [
          {
            list: " Logo Design - 3 Concept",
          },
          {
            list: " Final Logo 3D Intro.",
          },
          {
            list: " Business Cards (V.C)",
          },
          {
            list: " Envelope",
          },
          {
            list: " A4 Letterhead",
          },
          {
            list: " Letterhead Word Template",
          },
          {
            list: " Email signature",
          },
          {
            list: " Facebook Cover / DP",
          },
        ],
        del: "Delivery in :",
        day: "7day",
        butonOne: "get Quote",
        buttonTwo: "details",
      },
      {
        url: "https://www.cyberhelpindia.com/chi-admin/products/4399LOGO.png",
        name: "Logo Design",
        pra: "3 NOS. CONCEPT DESIGN",
        infoList: [
          {
            list: " Full color and black and white high-quality file formats",
          },
          {
            list: " The importance of high-resolution file formats",
          },
          {
            list: " Final Color Codes",
          },
          {
            list: " Final Font Formats",
          },
          {
            list: " 3 Nos. Concept Design",
          },
          {
            list: " 1 Final Logo 3D Intro.",
          },
          {
            list: " Mock design ready for the future.",
          },
          {
            list: " Logo Ready with Brand Instructions.",
          },
        ],
        del: "Delivery in :",
        day: "7day",
        butonOne: "get Quote",
        buttonTwo: "details",
      },
    ];

    return (
      <div>
        <section className={styles.website}>
          {websiteInfo.map((web) => {
            return (
              <div>
                <div className={styles.wrap}>
                  <div className={styles.imgs}>
                    <img src={web.url} alt="" />
                  </div>
                  <div className={styles.info}>
                    <h2>
                      <Link
                        href="online-product/nano-website-design"
                        class="th-color"
                      >
                        {web.name}
                      </Link>
                    </h2>
                    <p>{web.pra}</p>
                    <ul>
                      {web.infoList.map((weblist) => {
                        return <li>{weblist.list}</li>;
                      })}
                    </ul>
                  </div>
                  <div className={styles.btn_grp}>
                    <h3>
                      {web.del} <span>{web.day}</span>{" "}
                    </h3>
                    <button>{web.butonOne}</button>
                    <button>{web.buttonTwo}</button>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
        <section className={styles.website_mobile}>
          <div className="container">
            <div className={styles.back_button}>
              <Link to="/service">
                <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
              </Link>
            </div>
            {websiteInfo.map((web) => {
              return (
                <div>
                  <div className={styles.wrap}>
                    <div className={styles.imgs}>
                      <img src={web.url} alt="" />
                    </div>
                    <div className={styles.info}>
                      <h2>
                        <Link
                          href="online-product/nano-website-design"
                          class="th-color"
                        >
                          {web.name}
                        </Link>
                      </h2>
                      <p>{web.pra}</p>
                      <div className={styles.btn_grp}>
                        <h3>
                          {web.del} <span>{web.day}</span>{" "}
                        </h3>
                        <button>{web.butonOne}</button>
                        <button>{web.buttonTwo}</button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    );
  }
}

export default Graphic;
