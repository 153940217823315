import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "../about/About.module.scss";
export class About extends Component {
  static propTypes = {};

  render() {
    document.title = "Datavedam-About";
    return (
      <div>
        <section className={styles.about}>
          <div className={styles.heading}>
            <h1>About</h1>
          </div>
          <div className="container">
            <div className={styles.info}>
              <p>
                Datavedam Specializes in Digital Transformation, specifically
                for SMEs. Datavedam is determined to digitally transform the
                journeys of SMEs from rural villages / Tier ll-lll cities of
                India. It helps to increase the efficiency and effectiveness of
                communication, collaboration and creation. It provides a wide
                range of services such as mobile app development, web app
                development, billing software, google business, whatsapp
                integrations, google ads, facebook ads, insta ads, graphic
                portfolio, website design, logo portfolio, QR code creation and
                many more. Datavedam focuses exclusively on customer needs. It
                suggests what kind of services the customer needs to improve
                their business. Datavedam Provides Google cloud managed
                services.
              </p>
              <h2>our vision</h2>
              <p>
                The present era is the modern era. And the contribution of
                technology in this modern era is undeniable. Keeping that in
                mind, our first goal is to digitize the rural and suburban areas
                of our country because even though the big cities of our country
                have been digitized, the technology has not reached the rural
                villages in that way.
                <br />
                <br />
                So our first step is to launch our service in rural villages and
                move ahead with big cities.
              </p>
              <h2>our misson</h2>
              <p>
                Datavedam becomes essential to our customers by providing
                differentiated services to help them achieve their aspirations.
                Datavedam superior service at 20% to 50% discount compared to
                other service providers in the market.
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default About;
