import React from "react";
import PropTypes from "prop-types";
import styles from "../footer/Footer.module.scss";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <div>
      <footer>
        <div className="container">
          <div className={styles.footer_info}>
            <div className={styles.footer_wrap}>
              <div className={styles.items}>
                <h2>Product</h2>
                <ul>
                  <li>
                    <Link to=""> Platform Overview</Link>
                  </li>
                  <li>
                    <Link to=""> Pricing</Link>
                  </li>
                  <li>
                    <Link to=""> Tech Stacks</Link>
                  </li>
                  <li>
                    <Link to=""> Demo</Link>
                  </li>
                </ul>
              </div>
              <div className={styles.items}>
                <h2>Services</h2>
                <ul>
                  <li>
                    <Link to="">Code Migration</Link>
                  </li>
                  <li>
                    <Link to="">Code Maintenance</Link>
                  </li>
                  <li>
                    <Link to="">Code Refactoring</Link>
                  </li>
                  <li>
                    <Link to="">Platform Maintenance</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.logo_wrap}>
              <h1>
                <Link>Datavedam</Link>
              </h1>
              <ul>
                <li>
                  <a
                    className={styles.fb}
                    href="https://www.facebook.com/datavedam"
                    target="_blank"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/datavedam/"
                    target="_blank"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/datavedam/about/"
                    target="_blank"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
              <p>
                Datavedam <br /> Bellandur <br /> Bangalore, 560100
              </p>
            </div>
          </div>

          <div className={styles.footer_bottom}>
            <p>© Datavedam 2022. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

Footer.propTypes = {};

export default Footer;
