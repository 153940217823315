import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styles from "../nav/Navbar.module.scss";
export class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      colorChange: false,
    };
    this.myFunction = this.myFunction.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.changeNavbarColor);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeNavbarColor);
  }

  myFunction() {
    console.log("button click");
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
  }

  changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      this.setState({
        colorChange: true,
      });
    } else {
      this.setState({
        colorChange: false,
      });
    }
  };
  render() {
    return (
      <>
        <header
          className={`${styles.navbar} ${
            this.state.colorChange ? styles.colorChange : ""
          }`}
        >
          <div className={styles.navbar_hlt}>
            <div className="container">
              <div className={styles.flx_nav}>
                <h6>
                  <Link href=""> Developer & designer IND</Link>
                </h6>
                <h6>
                  <Link>
                    <i className="fa fa-phone"></i> +91-8861270513 /
                    +91-8035836825
                  </Link>
                </h6>
              </div>
            </div>
          </div>

          {/* This is main menu */}
          <nav
            // className={`${styles.navbar_main_menu} ${
            //   this.state.colorChange ? styles.colorChange : ""
            // }`}
            className={styles.navbar_main_menu}
          >
            <div className="container">
              <div className={styles.main_menu}>
                <div className={styles.logo}>
                  <h1>
                    <Link to="">Datavedam</Link>
                  </h1>
                </div>
                <div className={styles.menu}>
                  <ul>
                    <li>
                      <Link to="/"> Home </Link>
                    </li>
                    <li>
                      <Link to="about"> About </Link>
                    </li>
                    {/* 
                    <li>
                      <Link to="/#web"> Web </Link>
                    </li>
                    <li>
                      <Link to="/#cloud"> Cloud </Link>
                    </li> */}
                    {/* <li>
                      <Link to="demo"> Demo </Link>
                    </li> */}
                    <li>
                      <HashLink to="/pricing"> Pricing </HashLink>
                    </li>
                    <li>
                      <Link to="service"> Services </Link>
                    </li>

                    {/* <li>
                      <Link to="contact"> Contact </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <header
          className={`${styles.mobile_nav} ${
            this.state.colorChange ? styles.colorChange : ""
          }`}
        >
          <div className={styles.navbar_hlt}>
            <div className="container">
              <div className={styles.flx_nav}>
                <h6>
                  <Link href=""> Developer & designer IND</Link>
                </h6>
                <h6>
                  <Link>
                    <i className="fa fa-phone"></i> +91-8861270513 /
                    +91-8035836825
                  </Link>
                </h6>
              </div>
            </div>
          </div>
          <nav>
            <div className="container">
              <div className={styles.nav_bar}>
                <div className={styles.logo}>
                  <Link to="">Datavedam</Link>
                </div>
                <div className={styles.toggle_button}>
                  <button
                    onClick={this.myFunction}
                    className={this.state.showMenu ? styles.menuicons : ""}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </header>

        {/* {this.state.showMenu && ( */}
        <div
          className={`${styles.toggle_menu}  ${
            this.state.showMenu ? styles.actives : ""
          }`}
        >
          <div className={styles.menus}>
            <ul className={styles.navbar_nav}>
              <li className={styles.nav_item}>
                <Link
                  to="/"
                  onClick={this.myFunction}
                  className={
                    this.state.showMenu ? styles.menuicons : "nav_link"
                  }
                >
                  Home
                </Link>
              </li>
              {/* <li className={styles.nav_item}>
                <HashLink
                  onClick={this.myFunction}
                  className={
                    this.state.showMenu ? styles.menuicons : "nav_link"
                  }
                  smooth
                  to="/#about"
                >
                  About
                </HashLink>
              </li> */}
              {/* <li className={styles.nav_item}>
                <HashLink
                  smooth
                  to="/#pricing"
                  onClick={this.myFunction}
                  className={
                    this.state.showMenu ? styles.menuicons : "nav_link"
                  }
                >
                  Pricing
                </HashLink>
              </li>
              <li className={styles.nav_item}>
                <HashLink
                  smooth
                  to="/#web"
                  onClick={this.myFunction}
                  className={
                    this.state.showMenu ? styles.menuicons : "nav_link"
                  }
                >
                  web
                </HashLink>
              </li>
              <li className={styles.nav_item}>
                <HashLink
                  smooth
                  to="/#cloud"
                  onClick={this.myFunction}
                  className={
                    this.state.showMenu ? styles.menuicons : "nav_link"
                  }
                >
                  cloud
                </HashLink>
              </li> */}
              <li className={styles.nav_item}>
                <Link
                  to="about"
                  onClick={this.myFunction}
                  className={
                    this.state.showMenu ? styles.menuicons : "nav_link"
                  }
                >
                  About
                </Link>
              </li>
              <li className={styles.nav_item}>
                <Link
                  to="pricing"
                  onClick={this.myFunction}
                  className={
                    this.state.showMenu ? styles.menuicons : "nav_link"
                  }
                >
                  Pricing
                </Link>
              </li>
              <li className={styles.nav_item}>
                <Link
                  to="service"
                  onClick={this.myFunction}
                  className={
                    this.state.showMenu ? styles.menuicons : "nav_link"
                  }
                >
                  service
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default Navbar;
