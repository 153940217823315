import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "../soft/Showf.module.scss";
import { Link } from "react-router-dom";
export class Showf extends Component {
  static propTypes = {};

  render() {
    const websiteInfo = [
      {
        url: "https://www.cyberhelpindia.com/chi-admin/products/15942378246187566.jpg",
        name: "Medical Appointment Pro",
        pra: "FOR HOSPITAL / NURSING HOME / CHAMBER",
        infoList: [
          {
            list: " FULLY MOBILE RESPONSIVE.",
          },
          {
            list: " 10000 NOS. SMS INTEGRATION,",
          },
          {
            list: " PAYMENT GATEWAY RAZORPAY / PAYU",
          },
          {
            list: " DEDICATED DOMAIN NAME.",
          },
          {
            list: " SSL SECURITY CERTIFIED.",
          },
          {
            list: " 5 GB SHARED CLOUD HOSTING",
          },
          {
            list: " ADMIN PART DESIGN IN HTML/CSS PROGRAMME IN PHP,",
          },
          {
            list: " DATABASE MYSQL/ FIREBASE. IF ADDITIONAL",
          },
        ],
        del: "Delivery in :",
        day: "15day",
        butonOne: "get Quote",
        buttonTwo: "details",
      },
    ];
    return (
      <div>
        {" "}
        <section className={styles.website}>
          {websiteInfo.map((web) => {
            return (
              <div>
                <div className={styles.wrap}>
                  <div className={styles.imgs}>
                    <img src={web.url} alt="" />
                  </div>
                  <div className={styles.info}>
                    <h2>
                      <Link
                        href="online-product/nano-website-design"
                        class="th-color"
                      >
                        {web.name}
                      </Link>
                    </h2>
                    <p>{web.pra}</p>
                    <ul>
                      {web.infoList.map((weblist) => {
                        return <li>{weblist.list}</li>;
                      })}
                    </ul>
                  </div>
                  <div className={styles.btn_grp}>
                    <h3>
                      {web.del} <span>{web.day}</span>{" "}
                    </h3>
                    <button>{web.butonOne}</button>
                    <button>{web.buttonTwo}</button>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
        <section className={styles.website_mobile}>
          <div className="container">
            <div className={styles.back_button}>
              <Link to="/service">
                <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
              </Link>
            </div>
            {websiteInfo.map((web) => {
              return (
                <div>
                  <div className={styles.wrap}>
                    <div className={styles.imgs}>
                      <img src={web.url} alt="" />
                    </div>
                    <div className={styles.info}>
                      <h2>
                        <Link
                          href="online-product/nano-website-design"
                          class="th-color"
                        >
                          {web.name}
                        </Link>
                      </h2>
                      <p>{web.pra}</p>
                      <div className={styles.btn_grp}>
                        <h3>
                          {web.del} <span>{web.day}</span>{" "}
                        </h3>
                        <button>{web.butonOne}</button>
                        <button>{web.buttonTwo}</button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    );
  }
}

export default Showf;
