import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import styles from "../home/Home.module.scss";
import { Link } from "react-router-dom";

function Home(props) {
  document.title = "Datavedam - Home";
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    curentMode: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const mart = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    curentMode: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  const webPrice = [
    {
      id: "1",
      tier: "BRONZE",
      ulList: [
        { list: "2 page" },
        {
          list: "Static Pages",
        },
        {
          list: "Custom sub-domains",
        },
      ],
      price: "₹50/mo*",
      pstPriceNm: "Regular Price",
      pstPrice: "₹100/mo",
      btn: "Learn More",
      ulListServic: [
        { serviceList: "Great for Small Business" },
        {
          serviceList: "10+ Templates / categories",
        },
        {
          serviceList: "Sub-Domain, SSL",
        },
        {
          serviceList: "One time content change",
        },
      ],
    },
    {
      id: "2",
      tier: "SILVER",
      ulList: [
        { list: "4 page" },
        {
          list: "Static Pages",
        },
        {
          list: "Custom sub-domains",
        },
      ],
      price: "₹100/mo*",
      pstPriceNm: "Regular Price",
      pstPrice: "₹250/mo",
      btn: "Learn More",
      ulListServic: [
        { serviceList: "For Mid size Businesss" },
        {
          serviceList: "15+ Templates / categories",
        },
        {
          serviceList: "Sub-Domain, SSL",
        },
        {
          serviceList: "Two time content change",
        },
      ],
    },
    {
      id: "3",
      tier: "GOLD",
      ulList: [
        { list: "4 Static page" },
        {
          list: "1 Dynamic Page(Contact us)",
        },
        {
          list: "Custom sub-domains",
        },
      ],
      price: "₹200/mo*",
      pstPriceNm: "Regular Price",
      pstPrice: "₹300/mo",
      btn: "Learn More",
      ulListServic: [
        { serviceList: "For Businesss with daily new customers" },
        {
          serviceList: "15+ Templates / categories",
        },
        {
          serviceList: "Sub-Domain, SSL",
        },
        {
          serviceList: "Two time content change",
        },
        {
          serviceList: "Contact us Database",
        },
      ],
    },
    {
      id: "4",
      tier: "PLATINUM",
      ulList: [
        { list: "Custom Web Dev" },
        {
          list: "Payment Gateway Integrations",
        },
        {
          list: "Custom Domain Name",
        },
      ],
      price: "₹300/mo*",
      pstPriceNm: "Regular Price",
      pstPrice: "₹500/mo",
      btn: "Learn More",
      ulListServic: [
        { serviceList: "For interactive business" },
        {
          serviceList: "Custom-Domain, SSL",
        },
        {
          serviceList: "Two time content change",
        },
        {
          serviceList: "Analytics Dashboard",
        },
      ],
    },
  ];
  const cloudPrice = [
    {
      id: "1",
      tier: "TIER I",
      ulList: [
        { list: "Migrate your existing infrastrucure to cloud" },
        {
          list: "based solution",
        },
      ],
      price: "15 days*",
      pstPriceNm: "Billed Weekly",

      btn: "Learn More",
      ulListServic: [
        { serviceList: "For 1st time cloud users" },
        {
          serviceList: "Source Control repositories",
        },
      ],
    },
    {
      id: "2",
      tier: "TIER II",
      ulList: [
        { list: "Re-architect your old datapipeline" },
        {
          list: "with serverless architecure",
        },
      ],
      price: "30 days*",
      pstPriceNm: "Billed Daily",
      btn: "Learn More",
      ulListServic: [
        { serviceList: "For existing users" },
        {
          serviceList: "Cost Optimization",
        },
        {
          serviceList: "New Architecture Block Diagrams",
        },
        {
          serviceList: "Access Policy and Security",
        },
      ],
    },
    {
      id: "3",
      tier: "TIER III",
      ulList: [
        { list: "Create a data analytics piepline" },
        {
          list: "with serverless architecure",
        },
      ],
      price: "45 days*",
      pstPriceNm: "Billed Hourly",

      btn: "Learn More",
      ulListServic: [
        { serviceList: "For users who wants to build a modern data stack" },
        {
          serviceList: "Source Code Versioning",
        },
        {
          serviceList: "CI/CD pipelines",
        },
        {
          serviceList: "Unit Test Case",
        },
        {
          serviceList: "Access Policy and Security",
        },
      ],
    },
    {
      id: "4",
      tier: "TIER IV",
      ulList: [{ list: "Create a AI / ML piepline with cloud infrastrucure" }],
      price: "60 days*",
      pstPriceNm: "Billed Hourly",

      btn: "Learn More",
      ulListServic: [
        { serviceList: "Wiki Page" },
        {
          serviceList: "Source Code Versioning",
        },
        {
          serviceList: "CI/CD and Unit Test",
        },
        {
          serviceList: "Access Policy and Security",
        },
        {
          serviceList: "AI/ML Model Optimization",
        },
      ],
    },
  ];
  const demoProjects = [
    {
      url: "assets/market.jpeg",
      name: "Mart",
    },
    {
      url: "assets/hiringhippo.jpeg",
      name: "Hiringhippo",
    },
    {
      url: "assets/sankir.jpeg",
      name: "Sankir Technologies",
    },
    {
      url: "assets/ihm.jpeg",
      name: "IHM Bengalore",
    },
    {
      url: "assets/hebar.png",
      name: "Hebar",
    },
    {
      url: "assets/coda.png",
      name: "Coda",
    },

    {
      url: "assets/tailor.jpeg",
      name: "Tailor Shop",
    },
    {
      url: "assets/bitcoin.png",
      name: "Bitcoin",
    },
    {
      url: "assets/silon.png",
      name: "Silon",
    },
    {
      url: "assets/yellow_interia.png",
      name: "Yellow Interia",
    },
    {
      url: "assets/menu.png",
      name: "Menu",
    },
  ];

  return (
    <div>
      <section className={styles.banner}>
        <div className="container">
          <div className={styles.item_wrap}>
            <div className={styles.left_info}>
              <h5>WEB ENABLEMENT</h5>
              <h1>Go Online in matter of minutes</h1>
              <Link>Go online</Link>
              <p>Starting at</p>
              <h2>₹50/mo*</h2>
              <ul>
                <li>Free Sub Domain for 1st year</li>
                <li>Free SSL & Speed Boosting CDN</li>
                <li>India Server location available</li>
                <li>Expert 24/7 Support</li>
              </ul>
            </div>
            <div className={styles.right_info}>
              <h5>CLOUD ENABLEMENT</h5>
              <h1>Using Cloud is easy</h1>
              <Link>Start Building</Link>
              <p>Starting at</p>
              <h2>₹500/mo*</h2>
              <ul>
                <li>Lift & Shift to any public cloud</li>
                <li>
                  Rearchitect your old applications to cloud based solutions
                </li>
                <li>CI/CD Integrations</li>
                <li>Expert 24/7 Support</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.web_ebmt} id="web">
        <div className="container">
          <div className={styles.web_hding}>
            <h1>Get your business to digital with 99.99% uptime.</h1>
            <p>
              Whether you're looking to build a website, blog or online store,
              Datavedam can get you started with a Web Enablement plan tailored
              to your specific needs.
            </p>
          </div>
          <Slider {...settings}>
            {webPrice.map((web) => {
              return (
                <div className={styles.item}>
                  <div className={styles.besic_price}>
                    <h2>{web.tier}</h2>
                    <ul>
                      {web.ulList.map((webs) => {
                        return <li>{webs.list}</li>;
                      })}
                    </ul>
                    <h3>{web.price}</h3>
                    <h6>
                      {web.pstPriceNm} <span>{web.pstPrice}</span>{" "}
                    </h6>
                    <Link to="">{web.btn}</Link>
                    <ul>
                      {web.ulListServic.map((servic) => {
                        return <li>{servic.serviceList}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>

      <section className={styles.cloud_ebmt} id="cloud">
        <div className="container">
          <div className={styles.web_hding}>
            <h1>
              Deploy your applications on Cloud with Modern Serverless
              Architecture.
            </h1>
            <p>
              Whether you're looking for new cloud based applications or
              migrating to cloud, datavedam can get you started to cloud
              tailored to your specific needs.
            </p>
          </div>
          <Slider {...settings}>
            {cloudPrice.map((web) => {
              return (
                <div className={styles.item}>
                  <div className={styles.besic_price}>
                    <h2>{web.tier}</h2>
                    <ul>
                      {web.ulList.map((webs) => {
                        return <li>{webs.list}</li>;
                      })}
                    </ul>
                    <h3>{web.price}</h3>
                    <h6>
                      {web.pstPriceNm} <span>{web.pstPrice}</span>{" "}
                    </h6>
                    <Link to="">{web.btn}</Link>
                    <ul>
                      {web.ulListServic.map((servic) => {
                        return <li>{servic.serviceList}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>

      <section className={styles.demo_projects} id="demo">
        <div className="container">
          <h1>Demo Projects</h1>
          <Slider {...mart}>
            {demoProjects.map((demo) => {
              return (
                <div className={styles.items}>
                  <Link to={demo.link}>
                    <div className={styles.projects}>
                      <img src={demo.url} alt="" />
                      <h3>{demo.name}</h3>
                    </div>
                  </Link>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    </div>
  );
}

Home.propTypes = {};

export default Home;
