import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "../google/Google.module.scss";
import { Link } from "react-router-dom";
export class Google extends Component {
  static propTypes = {};
  render() {
    const websiteInfo = [
      {
        url: "https://www.cyberhelpindia.com/chi-admin/products/8476googlead.png",
        name: "Google AD Startup",
        pra: "FOR EDUCATION CONSULTANCY, SMALL SCALE BUSINESS,",
        infoList: [
          {
            list: " 5 Locations In India.",
          },
          {
            list: " Landing Page Design & Optimization",
          },
          {
            list: " 100 Nos. Keyword",
          },
          {
            list: " Google Page Speed Optimization",
          },
          {
            list: " Keyword Discovery and Selection",
          },
          {
            list: " Campaign & Adgroup Development",
          },
          {
            list: " Paid Advertising (PPC) Strategy",
          },
          {
            list: " Google Webmasters Setup",
          },
          {
            list: " Google Analytics Setup",
          },
          {
            list: " Bid Management",
          },
        ],
        del: "Delivery in :",
        day: "30 day",
        butonOne: "get Quote",
        buttonTwo: "details",
      },
      {
        url: "https://www.cyberhelpindia.com/chi-admin/products/1537301986844587.png",
        name: "Google AD Standard",
        pra: "GOOGLE TEXT AD / DISPLAY AD - 150 KEYWORDS",
        infoList: [
          {
            list: " 10 Locations In India.",
          },
          {
            list: " Landing Page Design & Optimization",
          },
          {
            list: " 150 Nos. Keyword",
          },
          {
            list: " Google Page Speed Optimization",
          },
          {
            list: " Keyword Discovery and Selection",
          },
          {
            list: " Campaign & Adgroup Development",
          },
          {
            list: " Paid Advertising (PPC) Strategy",
          },
          {
            list: " Google Webmasters Setup",
          },
          {
            list: " Google Analytics Setup",
          },
        ],
        del: "Delivery in :",
        day: "30 day",
        butonOne: "get Quote",
        buttonTwo: "details",
      },
      {
        url: "https://www.cyberhelpindia.com/chi-admin/products/15373034797746318.png",
        name: "Google AD Advance",
        pra: "GOOGLE TEXT AD / DISPLAY AD / CALL AD - 200 KEYWORDS",
        infoList: [
          {
            list: " AD Location: 20 Cities All Around India.",
          },
          {
            list: " Landing Page Design & Optimization",
          },
          {
            list: " 200 Nos. Keyword",
          },
          {
            list: " Google Page Speed Optimization  ",
          },
          {
            list: " Keyword Discovery and Selection",
          },
          {
            list: " Campaign & Adgroup Development",
          },
          {
            list: " Paid Advertising (PPC) Strategy",
          },
          {
            list: " Google Webmasters Setup",
          },
          {
            list: " Google Analytics Setup",
          },
        ],
        del: "Delivery in :",
        day: "30 day",
        butonOne: "get Quote",
        buttonTwo: "details",
      },
    ];

    return (
      <div>
        <section className={styles.website}>
          {websiteInfo.map((web) => {
            return (
              <div>
                <div className={styles.wrap}>
                  <div className={styles.imgs}>
                    <img src={web.url} alt="" />
                  </div>
                  <div className={styles.info}>
                    <h2>
                      <Link
                        href="online-product/nano-website-design"
                        class="th-color"
                      >
                        {web.name}
                      </Link>
                    </h2>
                    <p>{web.pra}</p>
                    <ul>
                      {web.infoList.map((weblist) => {
                        return <li>{weblist.list}</li>;
                      })}
                    </ul>
                  </div>
                  <div className={styles.btn_grp}>
                    <h3>
                      {web.del} <span>{web.day}</span>{" "}
                    </h3>
                    <button>{web.butonOne}</button>
                    <button>{web.buttonTwo}</button>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
        <section className={styles.website_mobile}>
          <div className="container">
            <div className={styles.back_button}>
              <Link to="/service">
                <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
              </Link>
            </div>
            {websiteInfo.map((web) => {
              return (
                <div>
                  <div className={styles.wrap}>
                    <div className={styles.imgs}>
                      <img src={web.url} alt="" />
                    </div>
                    <div className={styles.info}>
                      <h2>
                        <Link
                          href="online-product/nano-website-design"
                          class="th-color"
                        >
                          {web.name}
                        </Link>
                      </h2>
                      <p>{web.pra}</p>
                      <div className={styles.btn_grp}>
                        <h3>
                          {web.del} <span>{web.day}</span>{" "}
                        </h3>
                        <button>{web.butonOne}</button>
                        <button>{web.buttonTwo}</button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    );
  }
}

export default Google;
