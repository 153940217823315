import React,{useState} from 'react'
import styles from "../watsaap_api/WatsappApi.module.scss"
function WatsappApi() {
    const [monthlyPlanPrice, setMonthlyPlanPrice] = useState(1100); // Initial monthly plan price
    const [count, setCount] = useState(1); // Initial plan count
    const [userCount, setUserCount] = useState(0);
    const [wabaCount, setWabaCount] = useState(0);
    
 
    const userPrice = 1000;
    const wabaPrice = 1500;
    

    const subTotal = (monthlyPlanPrice * count) + (userPrice * userCount) + (wabaPrice * wabaCount);
  
    const handleCountChange = (event) => {
      setCount(parseInt(event.target.value, 10) || 1);
    };

  return (
    <div>
      <section className={styles.whatsaap}>
        <div className='container'>
            <div className={styles.wrap}> 
                <div className={styles.image}>
                    <img src="assets/Wpimge.png" alrt=""/>
                </div>
                <div className={styles.head_info}>
                    <h1>WhatsApp Business Cloud <br></br> API – Monthly Plan</h1>
                    <h6>₹ 1100/- Month</h6>
                    <p className={styles.pera}>Grow your business with 1 WhatsApp Business Cloud API number, 9 users, 20.000 contacts, 
                    CRM & Unified team chat,  Auto Replies, API & Webhooks, Automation and Bot builder and more.</p>
                    
                    <form>
                        <div className={`${'form-group'} ${styles.dataInput}`}>
                            <label>Add user (+1000.00)</label>
                            <p>How many extra users do you need?</p>
                            <input
                            type='text'
                            className='form-control'
                            value={userCount}
                            onChange={(e) => setUserCount(Number(e.target.value))}
                            />
                        </div>
                        <div className={`${'form-group'} ${styles.dataInput}`}>
                            <label>Add extra WABA number (+1500.00)</label>
                            <p>How many extra  numbers you need?</p>
                            <input
                            type='text'
                            className='form-control'
                            value={wabaCount}
                            onChange={(e) => setWabaCount(Number(e.target.value))}
                            />
                        </div>
                        <hr></hr>
                        <div className={styles.box}>
                            <div className={styles.wats}>
                            <h3>{count}x WhatsApp Business Cloud API – Monthly Plan</h3>
                            <h3>₹ {monthlyPlanPrice * count} /-</h3>
                            </div>
                            {userCount > 0 && (
                            <div className={styles.adduser}>
                                <h6>Add User - {userCount}</h6>
                                <h6>₹ {userPrice * userCount}</h6>
                            </div>
                            )}
                            {wabaCount > 0 && (
                            <div className={styles.addwaba}>
                                <h6>Add Extra Number - {wabaCount}</h6>
                                <h6>₹ {wabaPrice * wabaCount}</h6>
                            </div>
                            )}
                        </div>
                        <hr></hr>
                        <div className={styles.subTotal}>
                            <h2>SubTotal ₹ {subTotal} /-</h2>
                        </div>
                        <div className={styles.button}>
                            <input type="number" value={count} onChange={handleCountChange} />
                            <button>Add to order</button>
                        </div>
                    </form>
                </div>
            </div>

              
        </div>
      </section>
    </div>
  )
}

export default WatsappApi
