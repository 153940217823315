import React from 'react'
import PropTypes from 'prop-types'
import style from "../demo/Demo.module.scss";

function Demo(props) {
  return (
    <div>
      this is demo
    </div>
  )
}

Demo.propTypes = {

}

export default Demo

